import React from 'react';
import Layout from '../components/Layout';
import InsightsTemplate from '../templates/InsightsTemplate';
import { graphql } from 'gatsby';

const InsightsPage = ({ data }) => {
	const insights = data.insights.edges.map((edge) => ({
		name: edge.node.name,
		title: edge.node.title,
		content: edge.node.content,
		published_date: edge.node.published_date,
		thumbnail_image: edge.node.thumbnail_image,
		masthead_image: edge.node.masthead_image,
		industries: edge.node.industries,
		slug: edge.node.fields.slug,
	}));
	return (
		<Layout>
			<InsightsTemplate insights={insights} />
		</Layout>
	);
};

export default InsightsPage;

export const query = graphql`
	query {
		insights: allInsightsJson(sort: { fields: [published_date], order: DESC }) {
			edges {
				node {
					title
					content
					published_date(formatString: "MMMM Do, YYYY")
					thumbnail_image
					masthead_image
					industries {
						industry
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;
