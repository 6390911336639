import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import CtaBar from '../components/CtaBar';
import ArrowLink from '../components/ArrowLink';
import { PageWrap } from '../styles/common';

export default ({ insights }) => {
	return (
		<PageWrap>
			<Helmet>
				<title>Insights | Spergel Corporate</title>
				<style>{`div#chat-widget-container {display: none;}`}</style>
			</Helmet>
			<div className="container" style={{ padding: '0 2rem' }}>
				<h2 style={{ fontSize: '2rem' }}>Insights</h2>
				<p style={{ marginBottom: '4rem' }}>
					We believe in providing strategic information that will help your
					business avoid future debt problems. We want to share our insights and
					years of experience with you. If learning is your goal and you seek
					the resources and articles that will help you avoid debt trouble –
					we’ve got you covered. The strategic business solutions we’ve
					developed over the years are at your disposal.
				</p>
				<ColWrap>
					<div className="columns is-multiline">
						{insights.map((insight, index) => {
							if (index === 0) {
								return [
									<div className="column is-12 big-guy" key={index}>
										<Featured src={insight.thumbnail_image}>
											<div className="left">
												<h4>{insight.published_date}</h4>
												<h3>{insight.title}</h3>
												<ArrowLink href={insight.slug}>READ MORE</ArrowLink>
											</div>
											<div className="right" />
										</Featured>
									</div>,
									<div className="column is-4 first-guy">
										<Tile>
											<Image src={insight.thumbnail_image} />
											<Lower>
												<h4>{insight.published_date}</h4>
												<h3>{insight.title}</h3>
												<ArrowLink href={insight.slug}>READ MORE</ArrowLink>
											</Lower>
										</Tile>
									</div>,
								];
							} else {
								return [
									<div className="column is-4" key={index}>
										<Tile>
											<Image src={insight.thumbnail_image} />
											<Lower>
												<h4>{insight.published_date}</h4>
												<h3>{insight.title}</h3>
												<ArrowLink href={insight.slug}>READ MORE</ArrowLink>
											</Lower>
										</Tile>
									</div>,
								];
							}
						})}
					</div>
				</ColWrap>
			</div>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const Tile = styled.div`
	img {
		width: 100%;
	}
`;

const Image = styled.div`
	width: 100%;
	height: 300px;
	background-image: ${(p) => `url(${p.src})`};
	background-size: cover;
	background-position: center center;
`;

const Lower = styled.div`
	background-color: #f2f2f2;
	min-height: 250px;
	padding: 1rem;
	a {
		margin-left: 0;
	}
	h4 {
		font-size: 0.75rem;
	}
`;

const Featured = styled.div`
	display: flex;
	.left {
		flex-basis: 50%;
		height: 450px;
		background-color: #f2f2f2;
		display: inline-block;
		padding: 1rem 2rem;
		a {
			margin-left: 0;
		}
		h4 {
			font-size: 0.75rem;
		}
		h3 {
			font-size: 2rem;
		}
	}
	.right {
		height: 450px;
		flex-basis: 50%;
		background-image: ${(p) => `url(${p.src})`};
		background-size: cover;
		background-position: center center;
		display: inline-block;
	}
`;

const ColWrap = styled.div`
	.big-guy {
		display: none;
		@media only screen and (min-width: 768px) {
			display: block;
		}
	}
	.first-guy {
		display: block;
		@media only screen and (min-width: 768px) {
			display: none;
		}
	}
	margin-bottom: 2rem;
`;
